import { FaLinkedin, FaEnvelope } from 'react-icons/fa'

const content = {
  tagline: "",
  title: "I'm Sally Darling",
  subtitle: "I'm an Austin based Researcher and Strategist.",
  links: [
    {
      to: 'https://www.linkedin.com/in/sally-darling/',
      text: 'Find me on LinkedIn',
      Icon: FaLinkedin,
      newTab: true,
    },
    { to: 'mailto:sallydarling@gmail.com',
      text: 'Send me an email',
      Icon: FaEnvelope,
      newTab: true,
    }
  ],
};

export default content;